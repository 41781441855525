<template>
    <v-container>
        <div style="margin: 60px 0;"></div>
        <!-- check route  -->
        <PopGallery />
    </v-container>
</template>

<script>
import PopGallery from '@/components/pop/Index'
import Landing from '../../../components/pop/Landing'

export default {
    components: {
        PopGallery,
        Landing
    },
    beforeMount() {
        
        const token = localStorage.getItem("refreshToken")
        if (!token) {
            this.$router.push('pop-gallery-land')
        }
        
    },
}
</script>