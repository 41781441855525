<template>
  <v-row>
    <!-- Logo -->
    <v-row no-gutters style="
      margin: 0px !important;
      padding: 0px !important;
      margin-top: -60px;
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,1);
      z-index: 10;
    ">
      <v-col cols="12" md="12">
        <div class="text-center">
          <landing-logo />
        </div>
      </v-col>
    </v-row>
  
  <div class="containerr">
    
    <!-- Content -->
    <v-row no-gutters>
      <!-- Left Slider -->
      <div class="slider-container" ref="leftSlider">
        <div class="image-container">
          <v-row>
            <v-col v-for="(image, index) in dublicatedImagesLeft" :key="index" :cols="image.type.includes('portrait') ? '6': '12'" class="image-col">
              <div style="width: 95%;">
                <img :src="image.src" alt="Image" class="grid-image" :class="{ 'lower': index % 2 !== 0 }" :style="image.type.includes('portrait') ? 'min-width: 100%; max-width:100%;' : 'min-width: 106%;'" />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- Form Container -->
      <div class="form-container" >

        <!-- Logo row -->
        <v-row>
          <v-img
            class="mx-auto mb-2"
            max-width="70%"
            max-height="100%"
            :src="'/assets/branding/PopDooh.png'"
          ></v-img>
        </v-row>

        <v-card 
          class="pa-3 pa-md-5 mx-auto card" 
          :dark="$vuetify.theme.dark"
          v-bind:class="{ flipme: cardOne[0] == 'flipped' }"
        >

          <!-- Flip icon-->
          <v-row
            :class="cardOne[0] == 'flipped' ? 'card__face--back': ''"
          >

            <v-tooltip top>
              
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    icon
                    elevation="0"
                    @click.prevent="toggleCard"
                    class="ml-auto"
                    :color="hover ? 'primary' : ''"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-flip-horizontal
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>Flip card</span>
            </v-tooltip>
          </v-row>

          <!-- Text row -->
          <div   
            v-if="cardOne[0] == 'start'"
            :class="cardOne[0] == 'flipped' ? 'card__face--back': ''"
          >
          <v-row 
            class="mt-2 mx-2 text-center"
            style="
              justify-content: center;
            "
          >
            <p>
              Welcome to our POP Gallery, </br> where the art of OOH comes to life.
            </p>
          </v-row>
          <!-- Form row -->
          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center">
                <!-- Password -->
                <v-text-field
                  color="primary"
                  label="SecretPhrase"
                  id="opensesame"
                  prepend-icon="mdi-lock-outline"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="doohSecret"
                  @change="credentialsErrorSecret = false"
                  :type="opensesame ? 'text' : 'password'"
                  :error="credentialsErrorSecret"
                  @click:append="opensesame = !opensesame"
                  @keyup.enter="openPopGallery"
                />

                 <!-- Error popup -->
                 <div class="ma-0 pa-0">
                  <span 
                    v-if="credentialsErrorSecret" 
                    style="color: #ff5252"
                  >{{errorMessage}}</span>
                </div>
              </div>
              <!-- Login -->
              <v-row style="width: 100%" class="justify-center my-4">
                <v-btn
                  id="loginBtn"
                  depressed
                  color="primary"
                  @click="openPopGallery"
                  :loading="loginPending"
                  :disabled="loginPending"
                >Explore</v-btn>
              </v-row>
            </v-col>
          </v-row>
          </div>

          <!-- Form row -->
          <v-row 
            v-if="cardOne[0] == 'flipped'"
            :class="cardOne[0] == 'flipped' ? 'card__face--back': ''"
          >
            <v-col cols="12" md="12">
              <div class="text-center">
                <!-- Username -->
                <v-text-field
                  autofocus
                  color="primary"
                  label="Email"
                  id='username'
                  prepend-icon="mdi-email"
                  v-model="email"
                  @change='credentialsError = false'
                  type="email"
                  :error="credentialsError"
                  @keyup.enter="nextFocusByID('password')"
                />
                
                <!-- Password -->
                <v-text-field
                  color="primary"
                  label="Password"
                  id='password'
                  prepend-icon="mdi-lock-outline"
                  :append-icon=" showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  @change='credentialsError = false'
                  :type=" showPassword ? 'text' : 'password'"
                  :error="credentialsError"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="loginUser"
                />
                
                <!-- Error popup -->
                <div class="ma-0 pa-0">
                  <span 
                    v-if="credentialsError" 
                    style="color: #ff5252"
                  >{{errorMessage}}</span>
                </div>

                <!-- Forgot password -->
                <div class="mb-4 text-center">
                  <a href="/#/password-reset-request">Forgot your password?</a>
                </div>

                <!-- ? -->
                <v-row>
                  <v-col cols="12" class="text-center">
                    <span style="color: #04B526;">{{$route.query.redirectMsg}}</span>
                  </v-col>
                </v-row>
                
                <!-- Login -->
                <v-btn 
                  id='loginBtn'
                  depressed
                  color="primary"
                  @click="loginUser"
                  :loading="loginPending"
                  :disabled="loginPending"
                >Login</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <!-- Right Slider -->
      <div class="slider-container" ref="rightSlider">
        <div class="image-container">
          <v-row>
            <v-col v-for="(image, index) in duplicatedImagesRight" :key="index" :cols="image.type.includes('portrait') ? '6': '12'" class="image-col">
              <div style="width: 95%;">
                <img :src="image.src" alt="Image" class="grid-image" :class="{ 'lower': index % 2 !== 0 }" :style="image.type.includes('portrait') ? 'min-width: 100%; max-width:100%;' : 'min-width: 106%;'" />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-row>
  </div>
  {{/** Misc elements Loader*/}}
    <v-overlay 
      :value="overlay" 
      class="text-center justify-center"
      opacity="1"
      z-index="999"
    >
        <v-progress-circular
            indeterminate
            size="64"
            class="ml-3"
        ></v-progress-circular>
    </v-overlay>
</v-row>
</template>

<script>
import gsap from 'gsap';
import LandingLogo from './LandingLogo.vue';
import CampaignController from '@/services/controllers/Campaign'
// API
import UserController from '@/services/controllers/User'
import signin from '@/mixins/auth/signin'
import secretPhrase from '@/mixins/auth/secretPhrase'

export default {
  name: "Landing",

  components: {
    LandingLogo,
  },

  data: () => ({
    images: [],
    isAnimating: false,
    startingAt: 0,
    totalPops: 0,
    pageSize: 40,
    sortBy: { name: 'Date Added', id: -1 },
    orderBy: { name: 'Descending', value: 'desc' },
    finalSearchValue: 'spotlight',
    opensesame: null,
    doohSecret: null,
    showPassword: false,
    credentialsError: false,
    credentialsErrorSecret : false,
    overlay: true,
    // Flip Card
    cardOne: {},
    // Login
    email: null,
    password: null,
    loginPending: false,
    errorMessage: null,
  }),

  async mounted() {
    await this.getPops()
    .then(() => {
      this.cardOne = ['start']
      this.overlay = false;
    })
    .catch((error) => {
      console.log(error);
      this.overlay = false;
    });
  },

  computed: {
    scrollContainerHeight() {
      return this.$refs.scrollContainer.clientHeight;
    },
    duplicatedImagesLeft() {
      const numDuplicates = 100;
      const duplicated = [];
      const leftImages = this.images.slice(0, this.images.length / 2);
      console.log('leftImages', leftImages.length)
      for (let i = 0; i < numDuplicates; i++) {
        duplicated.push(...leftImages);
      }
      return duplicated;
    },

    duplicatedImagesRight() {
      const numDuplicates = 100;
      const duplicated = [];
      const rightImages = this.images.slice(this.images.length / 2, this.images.length);
      console.log('rightImages', rightImages.length)
      for (let i = 0; i < numDuplicates; i++) {
        duplicated.push(...rightImages);
      }
      return duplicated;
    },
  },

  methods: {

    toggleCard() {
      if(this.cardOne[0] == 'start'){
        this.$set(this.cardOne, 0, 'flipped')
      } else {
        this.$set(this.cardOne, 0, 'start')
      }
    },

    async openPopGallery() {
      
        this.loginPending= true
        const data = {
            phrase: this.doohSecret
        }
        const response = await secretPhrase(data)
        if(response.status === 200) {
            this.credentialsErrorSecret = false;
            this.loginPending = false;
            setTimeout(() => {
              this.$router.push({ name: 'PopGallery' });
            }, 500);  
        } else {
          this.loginPending = false
          this.credentialsErrorSecret = true;
          if(!response.data)
            this.errorMessage = "Backend not running";
          else if(response.data.message)
            this.errorMessage = response.data.message;
        }
    },

    async loginUser() {
      this.loginPending= true
      const response = await signin(this.email, this.password)
      //console.log(response)
      if(response.status === 200) {
        this.credentialsError = false;
        // Set the theme preference 
        const res2 = await UserController.getUserSetting('NightMode')
        localStorage.setItem('isDarkMode', res2.data.value)
        this.$vuetify.theme.dark = res2.data.value
        //this.$router.push(this.$route.query.redirectFrom || { name: 'Campaigns' });
        this.loginPending= false
        // Redirect to Proof of Play
        setTimeout(() => {
          this.$router.push({ name: 'ProofOfPlay' });
        }, 500);  
      } else  {
        this.loginPending= false
        this.credentialsError = true;
        if(!response.data)
          this.errorMessage = "Backend not running";
        else if(response.data.message)
          this.errorMessage = response.data.message;
      }
    },


    async getPops() {
      await CampaignController.getPoPs('?pageSize=' + this.pageSize + '&startingAt=' + this.startingAt + '&searchValues=' + encodeURIComponent(this.finalSearchValue) + '&sortCategoryId=' + this.sortBy.id + '&orderBy=' + this.orderBy.value)
      .then((res) => {
        res.data.list.forEach((item) => {
          if (item.media.mimeType.includes('image'))
            this.images.push({ src: item.media.url, type: item.media.orientation.toLowerCase() });
        });
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].type.includes('portrait')) {
            if (
              this.images[i + 1] && this.images[i + 1].type.includes('landscape') && this.images[i - 1] && this.images[i - 1].type.includes('landscape')
              || this.images[i + 1] && this.images[i + 1].type.includes('portrait') && this.images[i + 2] && this.images[i + 2].type.includes('portrait')
            ) {
              this.images.splice(i, 1);
              i--;
            }
          }
        }
        
        this.isAnimating = true;
        this.startScrollingAnimation();
      })
      .catch((error) => {
        console.log(error);
      });
    },

    redirectToLogin() {
      this.$router.push({ name: 'Login', query: { land: 'true' } })
    },

    startScrollingAnimation() {
      const leftSlider = this.$refs.leftSlider;
      const rightSlider = this.$refs.rightSlider;
      let currentOffset = 0;

      const animateScroll = () => {
        if (this.isAnimating) {
          currentOffset += 1;

          if (leftSlider && rightSlider) {
            gsap.to(leftSlider, { duration: 5, y: -currentOffset });
            gsap.to(rightSlider, { duration: 5, y: -currentOffset });
          }

          requestAnimationFrame(animateScroll);
        }
      };

      animateScroll();
    },
  },
}
</script>

<style scoped>
.containerr {
  height: 82vh;
  overflow: hidden;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.slider-container {
  flex: 1;
  overflow: hidden;
  max-height: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.form-container {
  max-width: 400px;
  position: relative;
  margin: 0 20px;
  z-index: 1;
  margin-left: 10px;
  margin-top: 50px
}

.image-col {
  align-items: center;
  width: 50%;
  margin-bottom: -10px;
}

.grid-image {
  border-radius: 5px;
  min-height: 350px;
  max-height: 305px;
  object-fit: cover;
  box-shadow: inset;
}

.lower {
  margin-top: 0px;
}
/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}
.card {
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.card__face {
  align-items: start;
  backface-visibility: hidden;
}
.card__face--back {
  transform: rotateY(180deg);
}

</style>

<style lang="sass">
.container 
  max-width: 100%

</style>